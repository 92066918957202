import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import AppLayout from '../../components/layout';
import Doc from './component/Doc';
import { Routes } from '../../utils/route'
import './Document.css';
import { Spin } from 'antd';
import {
  DiffOutlined,
  CopyOutlined,
  AreaChartOutlined,
  RadarChartOutlined,
  FundOutlined,
  BoxPlotOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import Data from './component/Data';
import API from '../../api';
import { message } from 'antd';
import document_1 from "../../assets/svg/document_1.svg"
import document_2 from "../../assets/svg/document_2.svg"
import document_12 from "../../assets/svg/document_12.svg"
import document_13 from "../../assets/svg/document_13.svg"
import document_14 from "../../assets/svg/document_14.svg"
import document_15 from "../../assets/svg/document_15.svg"

const Document = () => {
  const { documentList } = API;

  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(true)
  const [load, setLoad] = useState(false)

  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
      }
    }
    Routes('manager', callback)
  }, [])



  const handler = num => {
    setLoad(true)
    if (num == 1) {
      documentList(3505).then(res => {
        setLoad(false)
        setState(res.data.data);
        if (!res.data.data) {
          message.error({ content: `NO DOCUMENTS FOUND`, duration: 2 });
        }
      });
    } else if (num == 2) {
      documentList(3530).then(res => {
        setLoad(false)
        setState(res.data.data);
        if (!res.data.data) {
          message.error({ content: `NO DOCUMENTS FOUND`, duration: 2 });
        }
      });
    } else if (num == 3) {
      documentList(3510).then(res => {
        setLoad(false)
        setState(res.data.data);
        if (!res.data.data) {
          message.error({ content: `NO DOCUMENTS FOUND`, duration: 2 });
        }
      });
    } else if (num == 4) {
      documentList(3515).then(res => {
        setLoad(false)
        setState(res.data.data);
        if (!res.data.data) {
          message.error({ content: `NO DOCUMENTS FOUND`, duration: 2 });
        }
      });
    } else if (num == 5) {
      documentList(3520).then(res => {
        setLoad(false)
        setState(res.data.data);
        if (!res.data.data) {
          message.error({ content: `NO DOCUMENTS FOUND`, duration: 2 });
        }
      });
    } else if (num == 6) {
      documentList(3525).then(res => {
        setLoad(false)
        setState(res.data.data);
        if (!res.data.data) {
          message.error({ content: `NO DOCUMENTS FOUND`, duration: 2 });
        }
      });
    }
  };
  const dataBlock = () => {
    setState(null);

  };
  if(!loading){
  return (
    <AppLayout>
      <section className="document_section">
        <div className="document">
          { /*<Doc
            img={document_1}
            name="drop off / pick up confirmations"
            click={() => handler(1)}
          />
          <Doc
            img={document_2}
            name="wash tickets"
            click={() => handler(2)}
          />*/}
          <Doc
            img={document_12}
            name="safety sheets (last contained)"
            click={() => handler(3)}
          />
          <Doc
            img={document_13}
            name="image (pre-wash)"
            icon="4"
            click={() => handler(4)}
          />
          <Doc
            img={document_14}
            name="images (wash complete)"
            icon="5"
            click={() => handler(5)}
          />
          {/* <Doc
            img={document_15}
            name="confined space (CSEP)"
            icon="6"
            click={() => handler(6)}
         />*/}
        </div>
        {load ?
          (
            <div className="document_loading">
              <Spin size="large" />
            </div>
          )
          :
          ("")

        }
        <div className={state ? 'data' : ''}>
          {state
            ? <div onClick={dataBlock} className="data_block">
              <CloseCircleOutlined />
            </div>
            : ''}
          <div className="generated_data">
            {state &&
              state.map(item => {
                return <Data data={item} />;
              })}
          </div>
        </div>
      </section>
    </AppLayout>
  );
}else{
  return(<div className="spinner-main"><Spin/></div>)
}
};
export default Document;
